import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomNode from "./CustomNode";
import VoiceBotNode from "./VoiceBotNode";
import IVRNode from "./IVRNode";
import CreatedNewIVR from "./CreatedNewIVR";
import { toast } from "react-toastify";
// import { H2, H3, H4, H5 } from "../Typography";
import Modal from "@mui/material/Modal";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Tooltip,
  Grid,
  Input,
  Switch,
  styled,
} from "@mui/material";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import "./SideDrawer.css";
import { MessageOutlined, NumbersSharp } from "@mui/icons-material";
import Box from "@mui/material/Box";
const nodeTypes = {
  custom: CustomNode,
  IVRNode: IVRNode,
  CreatedNewIVR: CreatedNewIVR,
  VoiceBotNode: VoiceBotNode,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "50%",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  bgcolor: "#F6F6F6",
  // pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "18px",
};
const ivrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "70%",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  bgcolor: "#F6F6F6",
  // pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "18px",
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#b2efb3",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const WorkflowBuilder = () => {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_API_URL_FLOW = process.env.REACT_APP_API_URL_FLOW;
  const REACT_APP_AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accessToken = urlParams.get("access_token");
  const domainUUID = urlParams.get("domain_uuid");
  // const domainUUID = "50f0827c-d18f-11ee-8379-0a95ea71a99c";
  // const accessToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJUZW5hbnQiLCJleHAiOjE3MjY4MTYwNjUsImlhdCI6MTcyNjcyOTY2NSwiaXNzIjoiYXV0aC1zZXJ2aWNlIiwibmJmIjoxNzI2NzI5NjY1LCJzdWIiOiI1MGYwODI3Yy1kMThmLTExZWUtODM3OS0wYTk1ZWE3MWE5OWMifQ.bHNODRPLLs2GUlri6d8Fp0MbbGQrdnwSxRWQVXr2u3g";

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedNodeType, setSelectedNodeType] = useState("1");
  const [selectedChannelType, setSelectedChannelType] = useState("1");
  const [selectedConfigurationType, setSelectedConfigurationType] =
    useState("1");
  const [configurationList, setConfigurationList] = useState("");
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [nodeName, setNodeName] = useState("");
  const [nodeData, setNodeData] = useState("");
  const [conditionAPI, setConditionAPI] = useState("");
  const [method, setMethod] = useState("1");
  const [payloadType, setPayloadType] = useState("1");
  const [payload, setPayload] = useState("");

  const [queue, setQueue] = useState("1");
  const [queueList, setQueueList] = useState("");

  const [selectedImage, setSelectedImage] = useState("");

  const [buttonList, setButtonList] = useState([]);
  const [buttonText, setButtonText] = useState("");

  const handleAddButton = () => {
    if (
      selectedImage == "layout_1" &&
      buttonList.length > 2 &&
      selectedChannelType == "whatsapp"
    ) {
      toast.info("Please Change the Layout!", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    if (buttonText.trim() !== "") {
      setButtonList([...buttonList, buttonText]);
      setButtonText("");
    }
  };

  const handleDeleteButton = (index) => {
    setButtonList(buttonList.filter((_, i) => i !== index));
  };

  const onNodesChange = (changes) =>
    setNodes((nds) => applyNodeChanges(changes, nds));
  const onEdgesChange = (changes) =>
    setEdges((eds) => applyEdgeChanges(changes, eds));
  const onConnect = (connection) => setEdges((eds) => addEdge(connection, eds));

  const getwhatsappConfiguration = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/whatsapp/get_whatsappConfiguration/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setConfigurationList(result.Whatsapp);
      })
      .catch((error) => console.error(error));
  };

  const gettelegramConfiguration = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/telegram/get_telegramConfiguration/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setConfigurationList(result.Telegram);
      })
      .catch((error) => console.error(error));
  };

  const getviberConfiguration = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/viber/get_viberConfiguration/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setConfigurationList(result.Viber);
      })
      .catch((error) => console.error(error));
  };

  const getWebChatConfiguration = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    myHeaders.append(
      "Referer",
      "https://multiverse-app.callibry.com/omnichannel/webchat-bot"
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://multiverse-app.callibry.com/call-center/get-webchat-configuration/50f0827c-d18f-11ee-8379-0a95ea71a99c?user_uuid=50f0827c-d18f-11ee-8379-0a95ea71a99c",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setConfigurationList(result.webchat_configuration);
      })
      .catch((error) => console.error(error));
  };

  const getVoiceBotConfiguration = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );
    myHeaders.append("Origin", "https://multiverse-app.callibry.com");

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/get-voicebot-configuration?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setConfigurationList(result.VoiceBotConfiguration);
      })
      .catch((error) => console.error(error));
  };
  const getQueuesList = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/facebook/get_queue_list/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setQueueList(result.Queue);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setNodes([]);
    setEdges([]);
    setSelectedConfigurationType("1");
    localStorage.removeItem("flow");
    if (selectedChannelType == "whatsapp") {
      getwhatsappConfiguration();
    }
    if (selectedChannelType == "telegram") {
      gettelegramConfiguration();
    }
    if (selectedChannelType == "viber") {
      getviberConfiguration();
    }
    if (selectedChannelType == "webchat") {
      getWebChatConfiguration();
    }
    if (selectedChannelType == "IVR") {
      getIvrList();
    }
    if (selectedChannelType == "voiceBot") {
      getVoiceBotConfiguration();
    }
  }, [selectedChannelType]);

  useEffect(() => {
    if (selectedNodeType == "chat") {
      getQueuesList();
    }
  }, [selectedNodeType]);

  const PublishChanges = () => {
    if (selectedChannelType == "1" || selectedConfigurationType === "1") {
      toast.info("Please select all the required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    const flow = { nodes, edges };

    console.log(flow);

    localStorage.setItem("flow", JSON.stringify(flow));
    const flowvalue = localStorage.getItem("flow");

    console.log(flowvalue);
    console.log(typeof flowvalue);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      configuration_id: selectedConfigurationType,
      flow: flowvalue,
      channel:
        selectedChannelType === "IVR"
          ? `${selectedChannelType}_${selectedConfigurationType}`
          : selectedChannelType,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/publishChanges?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == false) {
          toast.info(result.message, {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.info("Changes Published", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  // <-----------For Retrieving the flow on selecting configurations------->
  useEffect(() => {
    if (selectedConfigurationType != 1) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const raw = JSON.stringify({
        domain_uuid: domainUUID,
        configuration_id: selectedConfigurationType,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/getChanges?user_uuid=${domainUUID}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == true) {
            const Flow = result.data[0].flow;
            const localflow = JSON.parse(Flow);

            if (localflow) {
              const rehydratedNodes = localflow.nodes.map((node) => {
                if (
                  node.type === "message" ||
                  node.type === "chat" ||
                  node.type === "button" ||
                  node.type === "custom"
                ) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      label: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: "250px",
                            rowGap: "6px",
                          }}
                        >
                          <h2
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px",
                              textTransform: "capitalize",
                              color: "#094e6c",
                              borderBottom: "1px #006d77 solid",
                              paddingBottom: "3px",
                            }}
                          >
                            {node.type === "message"
                              ? "Message"
                              : node.type === "chat"
                              ? "Chat"
                              : "Button"}
                          </h2>
                          <h3
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              margin: "0px",
                              color: "#161a1d",
                            }}
                          >
                            Name:
                            <span style={{ fontWeight: "400" }}>
                              {node.data.nodeName}
                            </span>
                          </h3>
                          <p
                            style={{
                              border: "1px lightgrey solid",
                              borderRadius: "4px",
                              padding: "6px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              fontSize: "16px",
                              fontStyle: "italic",
                              backgroundColor: "#E9F6E6",
                              margin: "0px 5px 0px 0px",
                              textAlign: "justify",
                              width: "95%",
                            }}
                          >
                            {node.data.nodeData}
                          </p>

                          {node.type === "button" ||
                            (node.type === "custom" && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                {}
                                {node.data.buttonList.map(
                                  (buttonLabel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        marginBottom: "4px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            backgroundColor: "#006d77",
                                            borderRadius: "50%",
                                            marginRight: "8px",
                                          }}
                                        />
                                        <span>{buttonLabel}</span>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ))}
                        </div>
                      ),
                    },
                  };
                } else if (node.type === "IVRNode") {
                  return restoreIVRNode(node);
                } else if (node.type === "NewIVR") {
                  return restoreNewIVRNode(node);
                } else if (node.type === "CreatedNewIVR") {
                  return restoreCreatedNewIVR(node);
                } else if (node.type === "VoiceBotNode") {
                  return restoreVoiceBotNode(node);
                }
                return node;
              });

              setNodes(rehydratedNodes);
              setEdges(localflow.edges);
            }
          }
        })
        .catch((error) => console.error(error));
    }
  }, [selectedConfigurationType]);

  const restoreIVRNode = (node) => {
    if (node.type === "IVRNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  IVR
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name :{" "}
                  <span style={{ fontWeight: "400" }}>
                    {" "}
                    {node.data.nodeName}{" "}
                  </span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Type :{node.destinationType}
                </p>
                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Name :<br />
                  {node.destinationName}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreNewIVRNode = (node) => {
    if (node.type === "NewIVR") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                IVR
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name:
                <span style={{ fontWeight: "400" }}>{node.data.nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Type:
                {node.destinationType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Name: <br />
                {node.destinationName}
              </p>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreCreatedNewIVR = (node) => {
    if (node.type === "CreatedNewIVR") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  IVR
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {node.name}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Description :{node.ivrDescription}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreVoiceBotNode = (node) => {
    console.log(node, "from restore");
    if (node.type === "VoiceBotNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {node.conditionType == "message"
                  ? "Message"
                  : "welcome_message"
                  ? "Welcome Message"
                  : "connect_agent"
                  ? "Connect To Agent"
                  : "user_input"
                  ? "User Input"
                  : "invalid"
                  ? "Invalid Message"
                  : ""}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}>{node.name} </span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Type :{node.conditionType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Data :{node.conditionData}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {node.data.buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const getConfigurationNameById = (Id) => {
    const name = configurationList.find(
      (configuration) => configuration.appId === Id
    );
    return name ? name.configuration_name : "";
  };

  const getDestinationNameById = (Id) => {
    if (selectIvrDestinationType == "IVR") {
      const name = destinationNameList.find(
        (destination) => destination.ivr_menu_uuid === Id
      );
      return name ? name.ivr_menu_name : "";
    }
    if (selectIvrDestinationType == "QUEUE") {
      const name = destinationNameList.find(
        (destination) => destination.call_center_queue_uuid === Id
      );
      return name ? name.queue_name : "";
    }
    if (selectIvrDestinationType == "PLAYBACK") {
      const name = destinationNameList.find(
        (destination) => destination.pmt_uuid === Id
      );
      return name ? name.prompt_name : "";
    }
    if (selectIvrDestinationType == "VOICEMAIL") {
      const name = destinationNameList.find(
        (destination) => destination.extension_uuid === Id
      );
      return name ? name.extension : "";
    }
    if (selectIvrDestinationType == "HANGUP") {
      const name = destinationNameList.find(
        (destination) => destination.HangupId === Id
      );
      return name ? name.HangupName : "";
    }
    if (selectIvrDestinationType == "CALLBACK") {
      const name = destinationNameList.find(
        (destination) => destination.CallbackId === Id
      );
      return name ? name.CallbackName : "";
    }
    if (selectIvrDestinationType == "VOICEBOT") {
      const name = destinationNameList.find(
        (destination) => destination.configuration_uuid === Id
      );
      return name ? name.configuration_name : "";
    }
    if (selectIvrDestinationType == "AIAGENT") {
      const name = destinationNameList.find(
        (destination) => destination.knowledge_base_uuid === Id
      );
      return name ? name.doc_name : "";
    }
  };

  const handleAddNode = () => {
    // <----------Validation for Omnichannel Nodes------>
    if (
      (nodeName === "" ||
        nodeData === "" ||
        selectedNodeType === "1" ||
        selectedConfigurationType == "1" ||
        selectedChannelType == "1") &&
      selectedChannelType != "IVR"
    ) {
      toast.info("Please Enter all the required fields", {
        position: "top-right",
        autoClose: 3000,
      });

      return;
    }

    // <---------------Validation for IVR Nodes----------->
    if (
      selectedChannelType === "IVR" &&
      (selectedConfigurationType == "1" ||
        nodeName === "" ||
        selectIvrDestinationName === "1" ||
        selectIvrDestinationType === "1")
    ) {
      toast.info("Please Enter all the required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    // <--------for adding IVR Node from right panel(2 cases)------->
    if (selectedChannelType == "IVR") {
      handleAddNodeForIVR();
      return;
    }

    // <------------for adding VoiceBot ---------------->

    if (selectedChannelType == "voiceBot") {
      handleAddNodeForVoiceBot();
      return;
    }
    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );
    myHeaders.append("Origin", "https://multiverse-app.callibry.com");

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const configurationName = getConfigurationNameById(
      selectedConfigurationType
    );

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      condition: "",
      condition_type: selectedNodeType,
      condition_data: nodeData,
      condition_api: selectedNodeType == "API" ? conditionAPI : "",
      method: selectedNodeType == "API" ? method : "",
      payload_type: selectedNodeType == "API" ? payloadType : "",
      payload: selectedNodeType == "API" ? payload : "",
      button: selectedNodeType == "button" ? convertedButtonList : "[]",
      layout: selectedImage,
      map_variable: "",
      action_type: "",
      app_id:
        selectedChannelType == "telegram"
          ? configurationName
          : selectedConfigurationType,
      queue_uuid: queue == "1" ? "" : queue,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var API_URL_CASE;
    if (selectedChannelType === "whatsapp") {
      API_URL_CASE = "whatsapp";
    } else if (selectedChannelType === "telegram") {
      API_URL_CASE = "telegram";
    } else if (selectedChannelType === "viber") {
      API_URL_CASE = "viber";
    }
    fetch(
      `${REACT_APP_API_URL}/${API_URL_CASE}/add-chatbot-case?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          const NodeId = result.NodeId;

          const newNode = {
            id: `${NodeId}`,
            type: selectedNodeType === "button" ? "custom" : selectedNodeType,
            selectedImage: selectedImage,
            style: { width: "fit-content" },
            position: { x: Math.random() * 200, y: Math.random() * 200 },
            data: {
              nodeName,
              nodeData,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid ",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />
                    {selectedNodeType}
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :<span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    {nodeData}
                  </p>

                  {selectedNodeType === "button" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "10px",
                      }}
                    >
                      {buttonList.map((buttonLabel, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "4px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#006d77",
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                            />
                            <span>{buttonLabel}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ),
              buttonList: selectedNodeType === "button" ? buttonList : [],
            },
            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);
          setNodeName("");
          setSelectedNodeType("1");
          setNodeData("");
          setIsDrawerOpen(false);
          setButtonList([]);
          setQueue("1");
          setSelectedImage("");
          setSelectIvrDestinationType("1");
          setSelectIvrDestinationName("1");
        } else {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const getVoiceMailIdById = (Id) => {
    const name = destinationNameList.find(
      (voicemail) => voicemail.extension_uuid === Id
    );
    return name ? name.voicemail_id : "";
  };

  const handleAddNodeForIVR = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );
    myHeaders.append("Origin", "https://multiverse-app.callibry.com");
    myHeaders.append(
      "Referer",
      "https://multiverse-app.callibry.com/queue/manage-ivr-options"
    );
    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    var IVR_MENU_OPTION_PARAM;
    if (selectIvrDestinationType == "IVR") {
      IVR_MENU_OPTION_PARAM = `transfer ${selectIvrDestinationName} XML IVR`;
    } else if (selectIvrDestinationType == "QUEUE") {
      IVR_MENU_OPTION_PARAM = `transfer ${selectIvrDestinationName} XML callcenter`;
    } else if (selectIvrDestinationType == "PLAYBACK") {
      const value = getFileLocationById(selectIvrDestinationName).substring(
        getFileLocationById(selectIvrDestinationName).lastIndexOf("/") + 1
      );
      IVR_MENU_OPTION_PARAM = `transfer ${value} XML PLAYBACK`;
    } else if (selectIvrDestinationType == "VOICEMAIL") {
      IVR_MENU_OPTION_PARAM = `transfer ${getVoiceMailIdById(
        selectIvrDestinationName
      )} XML VOICEMAIL`;
    } else if (selectIvrDestinationType == "HANGUP") {
      IVR_MENU_OPTION_PARAM = `hangup`;
    } else if (selectIvrDestinationType == "CALLBACK") {
      IVR_MENU_OPTION_PARAM = `transfer callback XML CALLBACK`;
    } else if (selectIvrDestinationType == "VOICEBOT") {
      IVR_MENU_OPTION_PARAM = `transfer ${selectIvrDestinationName} XML voicebot`;
    } else if (selectIvrDestinationType == "AIAGENT") {
      IVR_MENU_OPTION_PARAM = `transfer ${selectIvrDestinationName} XML ai_agent`;
    }

    const raw = JSON.stringify({
      ivr_menu_uuid: selectedConfigurationType,
      domain_uuid: domainUUID,
      items: {
        inputs: [
          {
            ivr_menu_option_digits: "1", //set blank
            ivr_menu_option_action: "menu-exec-app",
            ivr_menu_option_param: IVR_MENU_OPTION_PARAM,
            ivr_menu_option_order: "1", //set blank
            ivr_menu_option_description: nodeName,
            dtmf_uuid: "", //only set for external number
            ivr_menu_action_uuid: "", //set only for external number
          },
        ],
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/add-ivr-option-menu?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === "0") {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 3000,
          });
        } else if (result.Status === "1") {
          const NodeId = result.NodeId;
          // if destination type is ivr then make IVR type node(with 12 source nodes) --->IVRNode
          if (selectIvrDestinationType == "IVR") {
            const newNode = {
              id: `${NodeId}`,
              type: "IVRNode",
              style: { width: "fit-content" },
              position: { x: Math.random() * 200, y: Math.random() * 200 },
              destinationType: selectIvrDestinationType,
              destinationName: getDestinationNameById(selectIvrDestinationName),

              data: {
                nodeName,
                // nodeData,
                label: (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "89% 10%",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      gap: "4px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                        alignItems: "flex-start",
                      }}
                    >
                      <h2
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                          margin: "0px",
                          textTransform: "capitalize",
                          color: "#094e6c",
                          borderBottom: "1px #006d77 solid ",
                          paddingBottom: "3px",
                        }}
                      >
                        <MessageOutlined
                          style={{
                            fontSize: "18px",
                            color: "#006d77",
                            marginRight: "8px",
                          }}
                        />
                        IVR
                      </h2>

                      <h3
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          margin: "0px",
                          color: "#161a1d",
                        }}
                      >
                        Name :{" "}
                        <span style={{ fontWeight: "400" }}> {nodeName}</span>
                      </h3>

                      <p
                        style={{
                          border: "1px lightgrey solid",
                          borderRadius: "4px",
                          padding: "6px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          fontSize: "16px",
                          fontStyle: "italic",
                          backgroundColor: "#E9F6E6",
                          margin: "0px 5px 0px 0px",
                          textAlign: "justify",
                          width: "95%",
                        }}
                      >
                        Destination Type : {selectIvrDestinationType}
                      </p>
                      <p
                        style={{
                          border: "1px lightgrey solid",
                          borderRadius: "4px",
                          padding: "6px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          fontSize: "16px",
                          fontStyle: "italic",
                          backgroundColor: "#E9F6E6",
                          margin: "0px 5px 0px 0px",
                          textAlign: "justify",
                          width: "95%",
                        }}
                      >
                        Destination Name :<br />
                        {getDestinationNameById(selectIvrDestinationName)}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "3px",
                      }}
                    >
                      {[
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "*",
                        "#",
                      ].map((value, index) => (
                        <p style={{ margin: "0px" }}>{value}</p>
                      ))}
                    </div>
                  </div>
                ),
              },

              sourcePosition: "right",
              targetPosition: "left",
            };
            setNodes((nds) => [...nds, newNode]);
            setNodeName("");
            setSelectedNodeType("1");
            setNodeData("");
            setIsDrawerOpen(false);
            setButtonList([]);
            setQueue("1");
            setSelectedImage("");
            setSelectIvrDestinationType("1");
            setSelectIvrDestinationName("1");
          } else {
            // if destination type is other than IVR then make normal node(with 1 source node)---->NEWIVR
            const newNode = {
              id: `${NodeId}`,
              type: "NewIVR",
              style: { width: "fit-content" },
              position: { x: Math.random() * 200, y: Math.random() * 200 },
              destinationType: selectIvrDestinationType,
              destinationName: getDestinationNameById(selectIvrDestinationName),
              data: {
                nodeName,
                nodeData,
                label: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "250px",
                      rowGap: "6px",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        margin: "0px",
                        color: "#161a1d",
                      }}
                    >
                      Name :{" "}
                      <span style={{ fontWeight: "400" }}> {nodeName}</span>
                    </h3>

                    <p
                      style={{
                        border: "1px lightgrey solid",
                        borderRadius: "4px",
                        padding: "6px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        fontSize: "16px",
                        fontStyle: "italic",
                        backgroundColor: "#E9F6E6",
                        margin: "0px 5px 0px 0px",
                        textAlign: "justify",
                        width: "95%",
                      }}
                    >
                      Destination Type : {selectIvrDestinationType}
                    </p>
                    <p
                      style={{
                        border: "1px lightgrey solid",
                        borderRadius: "4px",
                        padding: "6px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        fontSize: "16px",
                        fontStyle: "italic",
                        backgroundColor: "#E9F6E6",
                        margin: "0px 5px 0px 0px",
                        textAlign: "justify",
                        width: "95%",
                      }}
                    >
                      Destination Name :<br />
                      {getDestinationNameById(selectIvrDestinationName)}
                    </p>
                  </div>
                ),
                buttonList: selectedNodeType === "button" ? buttonList : [],
              },
              sourcePosition: "right",
              targetPosition: "left",
            };
            setNodes((nds) => [...nds, newNode]);
            setSelectedNodeType("1");
            setNodeData("");
            setNodeName("");
            setIsDrawerOpen(false);
            setButtonList([]);
            setQueue("1");
            setSelectedImage("");
            setSelectIvrDestinationType("1");
            setSelectIvrDestinationName("1");
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const handleAddNodeForVoiceBot = () => {
    if (
      selectedConfigurationType == "1" ||
      nodeData == "" ||
      selectedNodeType == "1" ||
      voiceBotLanguage == "1" ||
      voiceBotVoiceType == "1"
    ) {
      toast.info("Please Enter all the required fields", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      condition: "",
      condition_type: selectedNodeType,
      condition_data: nodeData,
      language: voiceBotLanguage,
      configuration_uuid: selectedConfigurationType,
      voice_type: voiceBotVoiceType,
      queue_uuid: queueForVoiceBot,
      user_input_type: voiceBotUserInput,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/create-voicebot-case?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          const NodeId = result.NodeId;
          const newNode = {
            id: `${NodeId}`,
            type: "VoiceBotNode",
            style: { width: "fit-content" },
            position: { x: Math.random() * 200, y: Math.random() * 200 },
            name: nodeName,
            conditionType: selectedNodeType,
            conditionData: nodeData,
            UserInput: voiceBotUserInput,
            buttonList: buttonList,
            data: {
              nodeName,
              nodeData,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />

                    {selectedNodeType == "message"
                      ? "Message"
                      : "welcome_message"
                      ? "Welcome Message"
                      : "connect_agent"
                      ? "Connect To Agent"
                      : "user_input"
                      ? "User Input"
                      : "invalid"
                      ? "Invalid Message"
                      : ""}
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      // textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Condition Type: {selectedNodeType}
                  </p>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Condition Data : {nodeData}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ),
              buttonList: buttonList,
            },

            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);
          setSelectedNodeType("1");
          setNodeData("");
          setNodeName("");
          setIsDrawerOpen(false);
          setVoiceBotLanguage("1");
          setVoiceBotVoiceType("1");
          setVoiceBotUserInput("1");
          setButtonList([]);
        } else {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const handleEditNode = () => {
    // <---------------for dynamically deletion ----------->
    if (selectedNodeType == "CreatedNewIVR") {
      EditCreatedNewIVR();
      return;
    } else if (selectedNodeType == "NewIVR") {
      EditNewIVR();
      return;
    } else if (selectedNodeType == "IVRNode") {
      EditIVRNode();
      return;
    } else if (selectedChannelType == "voiceBot") {
      EditVoiceBotNode();
      return;
    } else {
      // for getting buttons list
      const convertedButtonList = JSON.stringify(
        buttonList.map((item) => [item])
      );

      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json, text/plain, */*");
      myHeaders.append("Accept-Language", "en-US,en;q=0.9");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      myHeaders.append("Connection", "keep-alive");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
      );
      myHeaders.append(
        "Referer",
        "https://multiverse-app.callibry.com/omnichannel/update-whatsapp-bot;WhatsappBotID=288"
      );
      myHeaders.append("Sec-Fetch-Dest", "empty");
      myHeaders.append("Sec-Fetch-Mode", "cors");
      myHeaders.append("Sec-Fetch-Site", "same-origin");
      myHeaders.append(
        "User-Agent",
        "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
      );
      myHeaders.append(
        "sec-ch-ua",
        '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
      );
      myHeaders.append("sec-ch-ua-mobile", "?0");
      myHeaders.append("sec-ch-ua-platform", '"Linux"');

      const raw = JSON.stringify({
        domain_uuid: domainUUID,
        id: selectedNodeId,
        condition: "",
        condition_type: selectedNodeType,
        condition_data: nodeData,
        condition_api: selectedNodeType == "API" ? conditionAPI : "",
        method: selectedNodeType == "API" ? method : "",
        payload_type: selectedNodeType == "API" ? payloadType : "",
        payload: selectedNodeType == "API" ? payload : "",
        button: selectedNodeType == "button" ? convertedButtonList : "[]",
        layout: selectedImage,
        map_variable: "",
        action_type: "",
        app_id: selectedConfigurationType,
        queue_uuid: queue == "1" ? "" : queue,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      var API_URL_CASE;
      if (selectedChannelType === "whatsapp") {
        API_URL_CASE = "whatsapp";
      } else if (selectedChannelType === "telegram") {
        API_URL_CASE = "telegram";
      } else if (selectedChannelType === "viber") {
        API_URL_CASE = "viber";
      }

      fetch(
        `${REACT_APP_API_URL}/${API_URL_CASE}/update-chatbot-case?user_uuid=${domainUUID}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          const newNode = {
            id: `${selectedNodeId}`,
            type: selectedNodeType === "button" ? "custom" : selectedNodeType,
            style: { width: "fit-content" },
            position: { x: Math.random() * 200, y: Math.random() * 200 },
            data: {
              nodeName,
              nodeData,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid ",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />

                    {selectedNodeType ? "custom" : "button"}
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    {nodeData}
                  </p>

                  {(selectedNodeType === "button" ||
                    selectedNodeType === "custom") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "10px",
                      }}
                    >
                      {buttonList.map((buttonLabel, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "4px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#006d77",
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                            />
                            <span>{buttonLabel}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ),
              buttonList:
                selectedNodeType === "button" || selectedNodeType === "custom"
                  ? buttonList
                  : [],
            },
            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);
          setNodeName("");
          setSelectedNodeType("1");
          setNodeData("");
          setIsDrawerOpen(false);
          setButtonList([]);
          setQueue("1");
          setSelectedImage("");
          setSelectIvrDestinationType("1");
          setSelectIvrDestinationName("1");
        })
        .catch((error) => console.error(error));
    }
  };

  const EditCreatedNewIVR = () => {};

  const EditNewIVR = () => {};

  const EditIVRNode = () => {};

  const EditVoiceBotNode = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      condition: "",
      condition_type: selectedNodeType,
      condition_data: nodeData,
      language: voiceBotLanguage,
      configuration_uuid: selectedConfigurationType,
      id: IDforVoiceBot.toString(),
      voice_type: voiceBotVoiceType,
      queue_uuid: voiceBotUserInput,
      user_input_type: voiceBotUserInput,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/update-voicebot-case?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          const newNode = {
            id: `${selectedNodeId}`,
            type: "VoiceBotNode",
            style: { width: "fit-content" },
            position: { x: Math.random() * 200, y: Math.random() * 200 },
            name: nodeName,
            conditionType: selectedNodeType,
            conditionData: nodeData,
            buttonList: buttonList,
            UserInput: voiceBotUserInput,
            data: {
              nodeName,
              nodeData,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />
                    {selectedNodeType == "message"
                      ? "Message"
                      : "welcome_message"
                      ? "Welcome Message"
                      : "connect_agent"
                      ? "Connect To Agent"
                      : "user_input"
                      ? "User Input"
                      : "invalid"
                      ? "Invalid Message"
                      : ""}
                    {/* Voice Bot */}
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      // textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Condition Type : {selectedNodeType}
                  </p>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Condition Data : {nodeData}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ),
              buttonList: buttonList,
            },
            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);

          setUpdateMode(false);
          setIsDrawerOpen(false);
          setNodeName("");
          setNodeData("");
          setSelectedNodeType("1");
          setButtonList([]);
          setQueue("1");
          setSelectedImage("");
          setVoiceBotLanguage("1");
          setVoiceBotVoiceType("1");
          setVoiceBotUserInput("1");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleEditNodeForIVR = () => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          return {
            ...node,
            type: selectedNodeType,
            data: {
              ...node.data,
              nodeName: nodeName,
              nodeData: nodeData,
              buttonList: selectedNodeType === "button" ? buttonList : [],
              selectedNodeType: selectedNodeType,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid ",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />
                    IVR
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :<span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Destination Type : {selectIvrDestinationType}
                  </p>
                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Destination Name :<br />
                    {getDestinationNameById(selectIvrDestinationName)}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {[
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "*",
                      "#",
                    ].map((value, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#006d77",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        />
                        <span>{value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ),
            },
          };
        }
        return node;
      })
    );

    setSelectedNodeType("1");
    setNodeData("");
    setIsDrawerOpen(false);
    setButtonList([]);
    setQueue("1");
    setSelectedImage("");
    setSelectIvrDestinationType("1");
    setSelectIvrDestinationName("1");
  };
  const deleteNode = (id) => {
    // <-----------to be used later for dynamic deletion ------------->
    // if (selectedNodeType == "CreatedNewIVR") {
    //   DeleteCreatedNewIVR();
    //   return;
    // }
    // if (selectedNodeType == "NewIVR") {
    //   DeleteNewIVR();
    //   return;
    // }
    // if (selectedNodeType == "IVRNode") {
    //   DeleteIVRNode();
    //   return;
    // }

    // <------------for temporary deletion ------------>
    if (selectedChannelType == "IVR") {
      handleDeleteNodeForIVR(id);
    }

    if (selectedChannelType == "voiceBot") {
      handleDeleteNodeForVoiceBot(id);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      id: id,
      domain_uuid: domainUUID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var API_URL_CASE;
    if (selectedChannelType === "whatsapp") {
      API_URL_CASE = "whatsapp";
    } else if (selectedChannelType === "telegram") {
      API_URL_CASE = "telegram";
    } else if (selectedChannelType === "viber") {
      API_URL_CASE = "viber";
    }

    fetch(
      `${REACT_APP_API_URL}/${API_URL_CASE}/delete-chatbot-case?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setNodes((nds) => nds.filter((node) => node.id !== id));
          setEdges((eds) =>
            eds.filter((edge) => edge.source !== id && edge.target !== id)
          );
          setUpdateMode(false);
          setIsDrawerOpen(false);
          setNodeName("");
          setNodeData("");
          setSelectedNodeType("1");
          setButtonList([]);
          setQueue("1");
          setSelectedImage("");
        }
      })
      .catch((error) => console.error(error));
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== id && edge.target !== id)
    );
    setUpdateMode(false);
    setIsDrawerOpen(false);
    setNodeName("");
    setNodeData("");
    setSelectedNodeType("1");
    setButtonList([]);
    setQueue("1");
    setSelectedImage("");
  };

  const DeleteCreatedNewIVR = () => {};
  const DeleteNewIVR = () => {};
  const DeleteIVRNode = () => {};

  const handleDeleteNodeForVoiceBot = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      id: IDforVoiceBot.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/delete-voicebot-case?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setNodes((nds) => nds.filter((node) => node.id !== id));
          setEdges((eds) =>
            eds.filter((edge) => edge.source !== id && edge.target !== id)
          );
          setUpdateMode(false);
          setIsDrawerOpen(false);
          setNodeName("");
          setNodeData("");
          setSelectedNodeType("1");
          setButtonList([]);
          setQueue("1");
          setSelectedImage("");
          setVoiceBotLanguage("1");
          setVoiceBotVoiceType("1");
          setVoiceBotUserInput("1");
        } else {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteNodeForIVR = (id) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== id && edge.target !== id)
    );
    setUpdateMode(false);
    setIsDrawerOpen(false);
    setNodeName("");
    setNodeData("");
    setSelectedNodeType("1");
    setButtonList([]);
    setQueue("1");
    setSelectedImage("");
    setSelectIvrDestinationType("1");
    setSelectIvrDestinationName("1");
  };

  const handleNodeClick = (event, node) => {
    console.log(node);

    setSelectedImage(node.selectedImage);
    setNodeData(node.data.nodeData);
    setIsDrawerOpen(true);
    setSelectedNodeId(node.id);
    setButtonList(node.data.buttonList);
    setUpdateMode(true);
    setNodeName(node.data.nodeName);
    if (node.type == "custom") {
      setSelectedNodeType("button");
    } else if (node.type == "VoiceBotNode") {
      setSelectedNodeType(node.conditionType);
      getParticularVoiceBotCaseById(node.id);
      setVoiceBotUserInput(node.UserInput);
      console.log(node.UserInput);
    } else {
      setSelectedNodeType(node.type);
    }
  };

  const getParticularVoiceBotCaseById = (nodeId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      configuration_uuid: selectedConfigurationType,
      node_id: nodeId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/get-voicebot-case-by-id?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setVoiceBotLanguage(result.VoiceBotCase[0].language);
        setVoiceBotUserInput(result.VoiceBotCase[0].user_input_type);
        setVoiceBotVoiceType(result.VoiceBotCase[0].voice_type);
        setVoiceBotUserInput(result.VoiceBotCase[0].queue_uuid);
        setIDforVoiceBot(result.VoiceBotCase[0].id);
      })
      .catch((error) => console.error(error));
  };

  const handleSaveLayout = () => {
    const LocalFlow = { nodes, edges };
    localStorage.setItem("LocalFlow", JSON.stringify(LocalFlow));
  };

  const handleRestoreLayout = () => {
    const LocalFlow = JSON.parse(localStorage.getItem("LocalFlow"));
    if (LocalFlow) {
      const rehydratedNodes = LocalFlow.nodes.map((node) => {
        if (node.type === "IVRNode") {
          return restoreIVRNode(node);
        } else if (node.type === "NewIVR") {
          return restoreNewIVRNode(node);
        } else if (node.type === "CreatedNewIVR") {
          return restoreCreatedNewIVR(node);
        } else if (node.type === "VoiceBotNode") {
          return restoreVoiceBotNode(node);
        } else if (
          node.type === "message" ||
          node.type === "chat" ||
          node.type === "button" ||
          node.type === "custom"
        ) {
          return {
            ...node,
            data: {
              ...node.data,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid",
                      paddingBottom: "3px",
                    }}
                  >
                    {node.type === "message" ? "Message" : "Chat"}
                  </h2>
                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name:
                    <span style={{ fontWeight: "400" }}>
                      {node.data.nodeName}
                    </span>
                  </h3>
                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    {node.data.nodeData}
                  </p>
                  {node.type === "button" ||
                    (node.type === "custom" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        {}
                        {node.data.buttonList.map((buttonLabel, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginBottom: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  backgroundColor: "#006d77",
                                  borderRadius: "50%",
                                  marginRight: "8px",
                                }}
                              />
                              <span>{buttonLabel}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              ),
            },
          };
        }
        return node;
      });

      setNodes(rehydratedNodes);
      setEdges(LocalFlow.edges);
    }
  };

  // <--------------------For IVR ------------------->

  const [AddIvrModal, setAddIvrModal] = useState(false);
  const [ivrName, setIvrName] = useState("");
  const [ivrExtention, setIvrExtention] = useState("");
  const [selectIvrGreetLong, setSelectIvrGreetLong] = useState("1");
  const [selectIvrGreetShort, setSelectIvrGreetShort] = useState("1");
  const [IvrMaxFailures, setIvrMaxFailures] = useState("2");
  const [IvrMaxTimeout, setIvrMaxTimeout] = useState("2");
  const [ivrTimeout, setIvrTimeout] = useState("3000");
  const [ivrSelectIvrExitAction, setIvrSelectIvrExitAction] = useState("1");
  const [ivrSelectIvrExitName, setIvrSelectIvrExitName] = useState("1");
  const [ivrSelectDirectDial, setIvrSelectDirectDial] = useState("false");
  const [ivrSelectRingBack, setIvrSelectRingBack] = useState("${in-ring}");
  const [ivrCallerIdPrefix, setIvrCallerIdPrefix] = useState("");
  const [ivrInvalidSound, setIvrInvalidSound] = useState("1");
  const [ivrExitSound, setIvrExitSound] = useState("1");
  const [ivrStatus, setIvrStatus] = useState(true);
  const [ivrDescription, setIvrDescription] = useState("");
  const [greetLongMessage, setGreetLongMessage] = useState("");
  const [greetShortMessage, setGreetShortMessage] = useState("");

  const [promptList, setPromptList] = useState("");
  const [exitNameList, setExitNameList] = useState("");

  const [selectIvrDestinationType, setSelectIvrDestinationType] = useState("1");
  const [selectIvrDestinationName, setSelectIvrDestinationName] = useState("1");
  const [destinationNameList, setDestinationNameList] = useState("");
  const [IDforVoiceBot, setIDforVoiceBot] = useState("");

  const getIvrList = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/getIvrList/${domainUUID}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setConfigurationList(result.IvrList);
        setExitNameList(result.IvrList);
        setDestinationNameList(result.IvrList);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (selectedConfigurationType == "createIvr") {
      setAddIvrModal(true);
      showPromptList();
    }
  }, [selectedConfigurationType]);

  const showPromptList = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    const formdata = new FormData();
    formdata.append("domain_uuid", domainUUID);
    formdata.append("id", "0");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/v1/callCenter/show-prompt-list/?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setPromptList(result.prompt_list);
        setExitNameList(result.prompt_list);
        setDestinationNameList(result.prompt_list);
      })
      .catch((error) => console.error(error));
  };

  const showQueueListForIvr = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );
    myHeaders.append("Origin", "https://multiverse-app.callibry.com");
    myHeaders.append(
      "Referer",
      "https://multiverse-app.callibry.com/queue/create-ivr"
    );
    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/v1/callCenter/show-queue-list/?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setExitNameList(result);
        setDestinationNameList(result);
        setQueueListforVoiceBot(result);
      })
      .catch((error) => console.error(error));
  };

  const showExtensionsList = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      limit: "",
      offset: "",
      search: "",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/v1/callCenter/show-extensions-list/?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const filteredResult = result.filter(
          (item) => item.voicemail_enable === "true"
        );
        setExitNameList(filteredResult);
        setDestinationNameList(filteredResult);
      })

      .catch((error) => console.error(error));
  };

  const showVoiceBotConfigurationList = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/get-voicebot-configuration?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDestinationNameList(result.VoiceBotConfiguration);
      })
      .catch((error) => console.error(error));
  };

  const showKnowledgeBaseList = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/ai/ai-agent/v1/get-knowledge-base?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDestinationNameList(result.KnowledgeBase);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setIvrSelectIvrExitName("1");
    if (ivrSelectIvrExitAction == "IVR") {
      getIvrList();
    }
    if (ivrSelectIvrExitAction == "QUEUE") {
      showQueueListForIvr();
    }
    if (ivrSelectIvrExitAction == "PLAYBACK") {
      showPromptList();
    }
    if (ivrSelectIvrExitAction == "VOICEMAIL") {
      showExtensionsList();
    }
  }, [ivrSelectIvrExitAction]);

  const getFileLocationById = (Id) => {
    const name = promptList.find(
      (fileLocation) => fileLocation.pmt_uuid === Id
    );
    return name ? name.file_location : "";
  };

  const handleAddIvr = async () => {
    // <---------------validations for adding ivr ----------->
    if (
      selectIvrGreetLong == "1" ||
      selectIvrGreetShort == "1" ||
      ivrName == "" ||
      ivrExtention == "" ||
      IvrMaxFailures == "" ||
      IvrMaxTimeout == "" ||
      ivrTimeout == "" ||
      ivrSelectIvrExitAction == "" ||
      ivrSelectDirectDial == "1" ||
      ivrSelectRingBack == "1" ||
      ivrInvalidSound == "1" ||
      ivrExitSound == "1"
    ) {
      toast.info("Please Enter all the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    var GreetShortValue = 0;
    if (selectIvrGreetShort === "tts_prompt") {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const raw = JSON.stringify({
        domain_uuid: domainUUID,
        name: ttsShortPromptName,
        text: ttsShortPromptText,
        language: ttsShortPromptLanguage,
        voice_type: ttsShortPromptVoiceType,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_API_URL}/call-center/tts-prompt?user_uuid=${domainUUID}`,
          requestOptions
        );
        const result = await response.json();
        GreetShortValue = result.UUID + ".mp3";
      } catch (error) {
        console.error(error);
      }
    } else {
      GreetShortValue = getFileLocationById(selectIvrGreetShort).substring(
        getFileLocationById(selectIvrGreetShort).lastIndexOf("/") + 1
      );
    }

    var GreetLongValue = 0;
    if (selectIvrGreetLong === "tts_prompt") {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const raw = JSON.stringify({
        domain_uuid: domainUUID,
        name: ttsLongPromptName,
        text: ttsLongPromptText,
        language: ttsLongPromptLanguage,
        voice_type: ttsLongPromptVoiceType,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_API_URL}/call-center/tts-prompt?user_uuid=${domainUUID}`,
          requestOptions
        );
        const result = await response.json();
        GreetLongValue = result.UUID + ".mp3";
      } catch (error) {
        console.error(error);
      }
    } else {
      GreetLongValue = getFileLocationById(selectIvrGreetLong).substring(
        getFileLocationById(selectIvrGreetLong).lastIndexOf("/") + 1
      );
    }

    console.log("reached 1");
    if (GreetShortValue != 0 || GreetLongValue != 0) {
      console.log("reached 2");
      AddIVRFunction(GreetShortValue, GreetLongValue);
    }
  };

  const AddIVRFunction = (GreetShortValue, GreetLongValue) => {
    const InvalidSoundValue = getFileLocationById(ivrInvalidSound).substring(
      getFileLocationById(ivrInvalidSound).lastIndexOf("/") + 1
    );

    const ExitSoundValue = getFileLocationById(ivrExitSound).substring(
      getFileLocationById(ivrExitSound).lastIndexOf("/") + 1
    );

    const myHeaders = new Headers();

    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      ivr_menu_name: ivrName,
      ivr_menu_extension: ivrExtention,
      ivr_menu_greet_long:
        selectIvrGreetLong == "say"
          ? `say:${greetLongMessage}`
          : GreetLongValue,
      ivr_menu_greet_short:
        selectIvrGreetShort == "say"
          ? `say:${greetShortMessage}`
          : GreetShortValue,
      ivr_menu_timeout: ivrTimeout,
      ivr_menu_exit_app: ivrSelectIvrExitAction,
      ivr_menu_exit_data: ivrSelectIvrExitName,
      ivr_menu_direct_dial: ivrSelectDirectDial,
      ivr_menu_ringback: ivrSelectRingBack,
      ivr_menu_cid_prefix: ivrCallerIdPrefix,
      ivr_menu_invalid_sound: InvalidSoundValue,
      ivr_menu_exit_sound: ExitSoundValue,
      ivr_menu_enabled: ivrStatus.toString(),
      ivr_callback_enabled: "false", //static
      ivr_menu_description: ivrDescription,
      is_enable: "true", //static
      ivr_menu_max_failures: IvrMaxFailures,
      ivr_menu_max_timeouts: IvrMaxTimeout,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/addIvr?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          const NodeId = result.NodeId;
          const newNode = {
            id: `${NodeId}`,

            type: "CreatedNewIVR",
            style: { width: "fit-content" },
            position: { x: Math.random() * 200, y: Math.random() * 200 },
            ivrDescription: ivrDescription,
            name: ivrName,
            data: {
              nodeName,
              nodeData,
              label: (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "89% 10%",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "5px",
                      alignItems: "flex-start",
                    }}
                  >
                    <h2
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        margin: "0px",
                        textTransform: "capitalize",
                        color: "#094e6c",
                        borderBottom: "1px #006d77 solid ",
                        paddingBottom: "3px",
                      }}
                    >
                      <MessageOutlined
                        style={{
                          fontSize: "18px",
                          color: "#006d77",
                          marginRight: "8px",
                        }}
                      />
                      IVR
                    </h2>

                    <h3
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        margin: "0px",
                        color: "#161a1d",
                      }}
                    >
                      Name :{" "}
                      <span style={{ fontWeight: "400" }}> {ivrName}</span>
                    </h3>

                    <p
                      style={{
                        border: "1px lightgrey solid",
                        borderRadius: "4px",
                        padding: "6px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        fontSize: "16px",
                        fontStyle: "italic",
                        backgroundColor: "#E9F6E6",
                        margin: "0px 5px 0px 0px",
                        textAlign: "justify",
                        width: "95%",
                      }}
                    >
                      IVR Description :<br /> {ivrDescription}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "3px",
                    }}
                  >
                    {[
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "*",
                      "#",
                    ].map((value, index) => (
                      <p style={{ margin: "0px" }}>{value}</p>
                    ))}
                  </div>
                </div>
              ),
            },

            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);

          setAddIvrModal(false);

          setIvrName("");
          setIvrExtention("");
          setSelectIvrGreetLong("1");
          setSelectIvrGreetShort("1");
          // setIvrMaxFailures("");
          // setIvrMaxTimeout("");
          // setIvrTimeout("");
          // setIvrSelectIvrExitAction("1");
          setIvrSelectDirectDial("1");
          // setIvrSelectRingBack("1");
          setIvrCallerIdPrefix();
          // setIvrInvalidSound("1");
          // setIvrExitSound("1");
          setIvrStatus(true);
          setSelectedConfigurationType("1");
          getIvrList();
        } else if (result.Status == "0") {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    setSelectIvrDestinationName("1");
    if (selectIvrDestinationType == "IVR") {
      getIvrList();
    }
    if (selectIvrDestinationType == "QUEUE") {
      showQueueListForIvr();
    }
    if (selectIvrDestinationType == "PLAYBACK") {
      showPromptList();
    }
    if (selectIvrDestinationType == "VOICEMAIL") {
      showExtensionsList();
    }
    if (selectIvrDestinationType == "HANGUP") {
      setDestinationNameList([
        {
          HangupId: "hangup",
          HangupName: "Hangup",
        },
      ]);
    }
    if (selectIvrDestinationType == "CALLBACK") {
      setDestinationNameList([
        {
          CallbackId: "callback",
          CallbackName: "Callback",
        },
      ]);
    }
    if (selectIvrDestinationType == "VOICEBOT") {
      showVoiceBotConfigurationList();
    }
    if (selectIvrDestinationType == "AIAGENT") {
      showKnowledgeBaseList();
    }
  }, [selectIvrDestinationType]);

  // <----------for background --------->
  const [backgroundVariant, setBackgroundVariant] = useState("cross");

  const handleVariantChange = (event) => {
    setBackgroundVariant(event.target.value);
  };

  // <-----------------for voicebot----------------->

  const [AddVoiceBotModal, setAddVoiceBotModal] = useState(false);
  const [voiceBotConfigurationName, setVoiceBotConfigurationName] =
    useState("");
  const [voiceBotDescription, setVoiceBotDescription] = useState("");
  const [voiceBotMaxRetry, setVoiceBotMaxRetry] = useState("0");
  const [voiceBotLanguage, setVoiceBotLanguage] = useState("1");
  const [voiceBotVoiceType, setVoiceBotVoiceType] = useState("1");
  const [voiceBotUserInput, setVoiceBotUserInput] = useState("1");
  const [queueListforVoiceBot, setQueueListforVoiceBot] = useState("");
  const [queueForVoiceBot, setQueueForVoiceBot] = useState("1");

  useEffect(() => {
    if (selectedConfigurationType == "createVoiceBot") {
      setAddVoiceBotModal(true);
    }
  }, [selectedConfigurationType]);

  const handleAddVoiceBotConfiguration = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      configuration_name: voiceBotConfigurationName,
      description: voiceBotDescription,
      max_retry: voiceBotMaxRetry,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/create-voicebot-configuration?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setAddVoiceBotModal(false);
          setVoiceBotConfigurationName("");
          setVoiceBotDescription("");
          setVoiceBotMaxRetry("0");
          getVoiceBotConfiguration();
          setSelectedConfigurationType("1");
          toast.info("Voice Bot Configuration Created Successfuly", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (selectedChannelType == "voiceBot") {
      showQueueListForIvr();
    }
  }, [selectedChannelType]);

  const handleDeleteConfiguration = () => {
    if (selectedChannelType == "whatsapp") {
      deleteWhatsappConfiguration();
    } else if (selectedChannelType == "voiceBot") {
      deleteVoiceBotConfiguration();
    } else if (selectedChannelType == "IVR") {
      deleteIVRConfiguration();
    }
  };

  const deleteWhatsappConfiguration = () => {};

  const deleteVoiceBotConfiguration = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      configuration_uuid: selectedConfigurationType,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center//tenant/delete-voicebot-configuration?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setNodes([]);
          setEdges([]);
          setSelectedConfigurationType("1");
          toast.info("Configuration Deleted Successfully", {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const deleteIVRConfiguration = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);

    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      ivr_menu_uuid: selectedConfigurationType,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/call-center/deleteIvrById?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setNodes([]);
          setEdges([]);
          setSelectedConfigurationType("1");
        }
      })
      .catch((error) => console.error(error));
  };

  const [apicurl, setApiCurl] = useState("");

  const [ttsLongPromptName, setTTSLongPromptName] = useState("");
  const [ttsLongPromptText, setTTSLongPromptText] = useState("");
  const [ttsLongPromptLanguage, setTtsLongPromptLanguage] = useState("1");
  const [ttsLongPromptVoiceType, setTtsLongPromptVoiceType] = useState("1");

  const [ttsShortPromptName, setTTSShortPromptName] = useState("");
  const [ttsShortPromptText, setTTSShortPromptText] = useState("");
  const [ttsShortPromptLanguage, setTtsShortPromptLanguage] = useState("1");
  const [ttsShortPromptVoiceType, setTtsShortPromptVoiceType] = useState("1");

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          padding: "30px",
          background: "#f0f0f0",
          border: "1px solid #ddd",
          justifyContent: "flex-end",
          display: "flex",
          paddingRight: "20%",
        }}
      >
        <FormControl
          style={{ marginRight: "10px", minWidth: 200, margin: "10px" }}
        >
          <Select
            labelId="channel-type-label"
            id="channel-type"
            value={selectedChannelType}
            onChange={(e) => setSelectedChannelType(e.target.value)}
          >
            <MenuItem value="1" disabled>
              Select Channel
            </MenuItem>
            <MenuItem value="voiceBot">Voice Bot</MenuItem>
            <MenuItem value="whatsapp">WhatsApp Bot</MenuItem>
            <MenuItem value="telegram">Telegram Bot</MenuItem>
            <MenuItem value="viber">Viber Bot</MenuItem>
            <MenuItem value="IVR">IVR</MenuItem>
            {/* <MenuItem value="webchat">Webchat</MenuItem> */}
          </Select>
        </FormControl>

        <FormControl
          style={{ marginRight: "10px", minWidth: 200, margin: "10px" }}
        >
          <Select
            labelId="configuration-type-label"
            id="configuration-type"
            value={selectedConfigurationType}
            onChange={(e) => setSelectedConfigurationType(e.target.value)}
          >
            <MenuItem value="1" disabled>
              Select Configuration
            </MenuItem>
            {selectedChannelType == "IVR" && (
              <MenuItem value="createIvr">
                <span
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                    fontSize: "18px",
                  }}
                >
                  Create IVR
                </span>
              </MenuItem>
            )}
            {selectedChannelType == "voiceBot" && (
              <MenuItem value="createVoiceBot">
                <span
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                    fontSize: "18px",
                  }}
                >
                  Create Voicebot
                </span>
              </MenuItem>
            )}
            {configurationList ? (
              configurationList.map((option) => (
                <MenuItem
                  key={option.appId ? option.appId : option.ivr_menu_uuid}
                  value={
                    option.appId
                      ? option.appId
                      : option.ivr_menu_uuid
                      ? option.ivr_menu_uuid
                      : option.configuration_uuid
                  }
                >
                  {option.configuration_name
                    ? option.configuration_name
                    : option.ivr_menu_name
                    ? option.ivr_menu_name
                    : option.configuration_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No data</MenuItem>
            )}
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setIsDrawerOpen(true);
          }}
          style={{ margin: "10px", minWidth: 180 }}
        >
          Add Node
        </Button>
        <Button
          style={{ margin: "10px", minWidth: 180 }}
          variant="contained"
          color="success"
          onClick={PublishChanges}
        >
          Publish Changes
        </Button>

        {selectedConfigurationType != "1" && (
          <Button
            style={{ margin: "10px", minWidth: 180 }}
            variant="contained"
            color="error"
            onClick={handleDeleteConfiguration}
          >
            Delete Configuration
          </Button>
        )}
      </div>
      <ReactFlowProvider>
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick}
          // onSelectionChange={onSelectionChange}
          deleteKeyCode={46}
          proOptions={{ hideAttribution: true }}
          fitView
        >
          <MiniMap
            maskColor="white"
            maskStrokeWidth={1}
            style={{ backgroundColor: "white", border: "2px solid grey" }}
          />
          <Controls
          // style={{
          //   right: 10,
          //   top: 'auto',
          //   bottom: 10,
          //   left: 'auto'
          // }}
          />
          <Background variant={backgroundVariant} />
          {/* <Background variant="cross" gap={16} size={2} /> */}
        </ReactFlow>
      </ReactFlowProvider>
      {/* side drawer  */}
      <div className={`side-drawer ${isDrawerOpen ? "open" : ""}`}>
        <IconButton
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={() => {
            setIsDrawerOpen(false);
            setNodeName("");
            setSelectedNodeType("1");
            setNodeData("");
            setUpdateMode(false);
            setButtonList([]);
            setQueue("1");
            setSelectedImage("");
            setSelectIvrDestinationType("1");
            setSelectIvrDestinationName("1");
          }}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
        <h2 className="drawer-title">
          {updateMode ? "Edit Node" : "Add Node"}
        </h2>

        <div className="drawer-content">
          <TextField
            label="Enter Node Name"
            variant="outlined"
            value={nodeName}
            onChange={(e) => setNodeName(e.target.value)}
            fullWidth
            style={{ marginBottom: "10px" }}
          />
          {selectedChannelType != "IVR" && selectedChannelType != "1" && (
            <>
              <FormControl style={{ marginBottom: "10px" }}>
                <Select
                  labelId="node-type-label"
                  id="node-type"
                  value={selectedNodeType}
                  onChange={(e) => setSelectedNodeType(e.target.value)}
                >
                  <MenuItem value="1" disabled>
                    Select Condition Type
                  </MenuItem>
                  {selectedChannelType != "whatsapp" && (
                    <MenuItem value="message">Message</MenuItem>
                  )}
                  {selectedChannelType != "whatsapp" && (
                    <MenuItem value="welcome_message">Welcome Message</MenuItem>
                  )}
                  {selectedChannelType != "whatsapp" && (
                    <MenuItem value="connect_agent">Connect to Agent</MenuItem>
                  )}
                  {selectedChannelType != "whatsapp" && (
                    <MenuItem value="user_input">User Input</MenuItem>
                  )}
                  {selectedChannelType != "whatsapp" && (
                    <MenuItem value="invalid">Invalid Message</MenuItem>
                  )}
                  {selectedChannelType != "voiceBot" && (
                    <MenuItem value="message">Message</MenuItem>
                  )}
                  {selectedChannelType != "voiceBot" && (
                    <MenuItem value="chat">Chat</MenuItem>
                  )}{" "}
                  {selectedChannelType != "voiceBot" && (
                    <MenuItem value="button">Button</MenuItem>
                  )}{" "}
                  {selectedChannelType != "voiceBot" && (
                    <MenuItem value="API">API</MenuItem>
                  )}
                </Select>
              </FormControl>

              <TextField
                label="Enter Condition Data"
                variant="outlined"
                value={nodeData}
                onChange={(e) => setNodeData(e.target.value)}
                fullWidth
                style={{ marginBottom: "10px" }}
              />

              {/* for Chat condition type  */}
              {selectedNodeType === "chat" && (
                <FormControl style={{ marginBottom: "10px" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={queue}
                    onChange={(e) => setQueue(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Queue
                    </MenuItem>
                    {queueList ? (
                      queueList.map((option) => (
                        <MenuItem key={option.id} value={option.queue_uuid}>
                          {option.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}

              {/* for Button condition type  */}
              {selectedNodeType === "button" && (
                <>
                  <p>Layout</p>
                  <label>
                    <div
                      style={{
                        display: "flex",
                        padding: "2px",
                        margin: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="radio"
                        value="layout_1"
                        style={{ border: "2px solid red", bottom: "20px" }}
                        checked={selectedImage === "layout_1"}
                        onChange={() => setSelectedImage("layout_1")}
                      />
                      <img
                        src={
                          selectedChannelType === "whatsapp"
                            ? require("./assets/WhatsApplayout.jpg")
                            : selectedChannelType === "telegram"
                            ? require("./assets/telegram_layout.jpg")
                            : null
                        }
                        alt="WhatsApp Layout 1"
                        style={{ width: "200px", height: "80px" }}
                      />
                    </div>
                  </label>

                  <label>
                    <div
                      style={{
                        display: "flex",
                        padding: "2px",
                        margin: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="radio"
                        value="layout_2"
                        checked={selectedImage === "layout_2"}
                        onChange={() => setSelectedImage("layout_2")}
                      />
                      <img
                        src={
                          selectedChannelType === "whatsapp"
                            ? require("./assets/WhatsApplayout2.jpg")
                            : selectedChannelType === "telegram"
                            ? require("./assets/telegram_layout_2.png")
                            : null
                        }
                        alt="WhatsApp Layout 2"
                        style={{ width: "200px" }}
                      />
                    </div>
                  </label>

                  {selectedImage != "" && (
                    <>
                      <TextField
                        label="Enter Button"
                        variant="outlined"
                        value={buttonText}
                        onChange={(e) => setButtonText(e.target.value)}
                        fullWidth
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleAddButton}
                      >
                        Add Button
                      </Button>
                      <Box mt={2}>
                        {buttonList.map((label, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="default"
                              style={{
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {label}
                            </Button>
                            <Tooltip title="Delete" placement="bottom">
                              <IconButton
                                color="error"
                                onClick={() => handleDeleteButton(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ))}
                      </Box>
                    </>
                  )}
                </>
              )}

              {/* for API condition type  */}
              {selectedNodeType === "API" && (
                <>
                  <TextField
                    label="Enter Condition API"
                    variant="outlined"
                    value={conditionAPI}
                    onChange={(e) => setConditionAPI(e.target.value)}
                    fullWidth
                    style={{ marginBottom: "10px" }}
                  />

                  <FormControl style={{ marginBottom: "10px" }}>
                    <Select
                      labelId="node-type-label"
                      id="node-type"
                      value={method}
                      onChange={(e) => setMethod(e.target.value)}
                    >
                      <MenuItem value="1" disabled>
                        Select Method
                      </MenuItem>{" "}
                      <MenuItem value="GET">GET</MenuItem>
                      <MenuItem value="POST">POST</MenuItem>
                      <MenuItem value="PUT">PUT</MenuItem>
                      <MenuItem value="DELETE">DELETE</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl style={{ marginBottom: "10px" }}>
                    <Select
                      labelId="node-type-label"
                      id="node-type"
                      value={payloadType}
                      onChange={(e) => setPayloadType(e.target.value)}
                    >
                      <MenuItem value="1" disabled>
                        Select Payload Type
                      </MenuItem>
                      <MenuItem value="query_param">Query Param</MenuItem>
                      <MenuItem value="request_body">Request Body</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    label="Enter Payload"
                    variant="outlined"
                    value={payload}
                    onChange={(e) => setPayload(e.target.value)}
                    fullWidth
                    style={{ marginBottom: "10px" }}
                  />
                </>
              )}
            </>
          )}

          {selectedChannelType === "IVR" && (
            <>
              <FormControl style={{ marginBottom: "10px" }}>
                <Select
                  labelId="node-type-label"
                  id="node-type"
                  value={selectIvrDestinationType}
                  onChange={(e) => setSelectIvrDestinationType(e.target.value)}
                >
                  <MenuItem value="1" disabled>
                    Select Destination Type
                  </MenuItem>
                  <MenuItem value="IVR">IVR</MenuItem>
                  <MenuItem value="QUEUE">QUEUE</MenuItem>
                  <MenuItem value="PLAYBACK">PLAYBACK</MenuItem>
                  <MenuItem value="VOICEMAIL">VOICEMAIL</MenuItem>
                  <MenuItem value="HANGUP">HANGUP</MenuItem>
                  <MenuItem value="CALLBACK">CALLBACK</MenuItem>
                  <MenuItem value="VOICEBOT">VOICEBOT</MenuItem>
                  <MenuItem value="AIAGENT">AI AGENT</MenuItem>
                  <MenuItem value="API">API</MenuItem>

                  {/* <MenuItem value="EXTERNALNUMBER">EXTERNAL NUMBER</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl style={{ marginBottom: "10px" }}>
                <Select
                  labelId="node-type-label"
                  id="node-type"
                  value={selectIvrDestinationName}
                  onChange={(e) => setSelectIvrDestinationName(e.target.value)}
                >
                  <MenuItem value="1" disabled>
                    Select Destination Name
                  </MenuItem>
                  {destinationNameList && selectIvrDestinationType != 1 ? (
                    destinationNameList.map((option) => (
                      <MenuItem
                        key={
                          option.ivr_menu_uuid
                            ? option.ivr_menu_uuid
                            : option.call_center_queue_uuid
                            ? option.call_center_queue_uuid
                            : option.pmt_uuid
                            ? option.pmt_uuid
                            : option.extension_uuid
                            ? option.extension_uuid
                            : option.HangupId
                            ? option.HangupId
                            : option.CallbackId
                            ? option.CallbackId
                            : option.configuration_uuid
                            ? option.configuration_uuid
                            : option.knowledge_base_uuid
                        }
                        value={
                          option.ivr_menu_uuid
                            ? option.ivr_menu_uuid
                            : option.call_center_queue_uuid
                            ? option.call_center_queue_uuid
                            : option.pmt_uuid
                            ? option.pmt_uuid
                            : option.extension_uuid
                            ? option.extension_uuid
                            : option.HangupId
                            ? option.HangupId
                            : option.CallbackId
                            ? option.CallbackId
                            : option.configuration_uuid
                            ? option.configuration_uuid
                            : option.knowledge_base_uuid
                        }
                      >
                        {option.ivr_menu_name
                          ? option.ivr_menu_name
                          : option.queue_name
                          ? option.queue_name
                          : option.prompt_name
                          ? option.prompt_name
                          : option.extension
                          ? option.extension
                          : option.HangupName
                          ? option.HangupName
                          : option.CallbackName
                          ? option.CallbackName
                          : option.configuration_name
                          ? option.configuration_name
                          : option.doc_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No data</MenuItem>
                  )}
                </Select>
              </FormControl>

              {selectIvrDestinationType == "API" && (
                <TextField
                  label="Enter CURL"
                  variant="outlined"
                  value={apicurl}
                  onChange={(e) => setApiCurl(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px" }}
                />
              )}
            </>
          )}

          {selectedChannelType == "voiceBot" && (
            <>
              <FormControl style={{ marginBottom: "10px" }}>
                <Select
                  labelId="node-type-label"
                  id="node-type"
                  value={voiceBotLanguage}
                  onChange={(e) => setVoiceBotLanguage(e.target.value)}
                >
                  <MenuItem value="1" disabled>
                    Select Language
                  </MenuItem>
                  <MenuItem value="en-US">English</MenuItem>
                  <MenuItem value="hi-IN">Hindi</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginBottom: "10px" }}>
                <Select
                  labelId="node-type-label"
                  id="node-type"
                  value={voiceBotVoiceType}
                  onChange={(e) => setVoiceBotVoiceType(e.target.value)}
                >
                  <MenuItem value="1" disabled>
                    Select Voice Type
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </>
          )}

          {(selectedNodeType == "message" ||
            selectedNodeType == "user_input") &&
            selectedChannelType != "whatsapp" && (
              <>
                <FormControl style={{ marginBottom: "10px" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={voiceBotUserInput}
                    onChange={(e) => setVoiceBotUserInput(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select User Input
                    </MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          {selectedNodeType == "connect_agent" && (
            <>
              <FormControl style={{ marginBottom: "10px" }}>
                <Select
                  labelId="node-type-label"
                  id="node-type"
                  value={queueForVoiceBot}
                  onChange={(e) => setQueueForVoiceBot(e.target.value)}
                >
                  <MenuItem value="1" disabled>
                    Select Queue
                  </MenuItem>
                  {queueListforVoiceBot ? (
                    queueListforVoiceBot.map((option) => (
                      <MenuItem
                        key={option.call_center_queue_uuid}
                        value={option.call_center_queue_uuid}
                      >
                        {option.queue_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No data</MenuItem>
                  )}{" "}
                </Select>
              </FormControl>
            </>
          )}

          {selectedChannelType == "voiceBot" && (
            <>
              <TextField
                label="Enter Button"
                variant="outlined"
                value={buttonText}
                onChange={(e) => setButtonText(e.target.value)}
                fullWidth
                style={{ marginBottom: "10px", marginTop: "10px" }}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddButton}
              >
                Add Button
              </Button>

              <Box mt={2}>
                {buttonList.map((label, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="default"
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {label}
                    </Button>
                    <Tooltip title="Delete" placement="bottom">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteButton(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ))}
              </Box>
            </>
          )}

          <Button
            variant="contained"
            color="success"
            style={{ marginTop: "15px", height: "50px" }}
            onClick={updateMode ? handleEditNode : handleAddNode}
            // className="add-node-btn"
          >
            {updateMode ? "Update Node" : "Add Node"}
          </Button>

          {updateMode && (
            <Button
              variant="contained"
              color="error"
              style={{ marginTop: "20px", height: "50px" }}
              onClick={() => {
                deleteNode(selectedNodeId);
              }}
              className="add-node-btn"
            >
              Delete Node
            </Button>
          )}
        </div>
      </div>
      {/* Modal for create IVR */}
      <Modal
        open={AddIvrModal}
        onClose={() => {
          setAddIvrModal(false);
          setSelectedConfigurationType("1");
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...ivrModalStyle,
            width: "80%",
            maxWidth: 1400,
            padding: "20px",
            borderRadius: "20px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            backgroundColor: "#f9f9f9",
            margin: "auto",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              maxHeight: "100%",
              overflowY: "auto",
              paddingRight: "7px",
              borderRadius: "inherit",
            }}
          >
            <p
              style={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#006d77",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Add New IVR
            </p>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  label="Enter IVR Name"
                  variant="outlined"
                  value={ivrName}
                  onChange={(e) => setIvrName(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />

                <TextField
                  label="Enter Extention"
                  variant="outlined"
                  value={ivrExtention}
                  onChange={(e) => setIvrExtention(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <InputLabel id="background-variant-label">
                    Select Greet Long
                  </InputLabel>

                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={selectIvrGreetLong}
                    label="Select Greet Long"
                    onChange={(e) => setSelectIvrGreetLong(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Greet Long
                    </MenuItem>
                    <MenuItem value="say">Say</MenuItem>
                    <MenuItem value="tts_prompt">TTS Prompt</MenuItem>

                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {selectIvrGreetLong == "say" ? (
                  <TextField
                    label="Enter Greet Long Message"
                    variant="outlined"
                    value={greetLongMessage}
                    onChange={(e) => setGreetLongMessage(e.target.value)}
                    fullWidth
                    style={{ marginBottom: "10px", width: "40%" }}
                  />
                ) : (
                  <div style={{ marginBottom: "10px", width: "40%" }}></div>
                )}
              </div>

              {/* <------------------------tts fields for greet long-----------------> */}
              {selectIvrGreetLong == "tts_prompt" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      marginBottom: "15px",
                      justifyContent: "space-around",
                    }}
                  >
                    <TextField
                      label="Enter Name for TTS Prompt"
                      variant="outlined"
                      value={ttsLongPromptName}
                      onChange={(e) => setTTSLongPromptName(e.target.value)}
                      fullWidth
                      style={{ marginBottom: "10px", width: "40%" }}
                    />
                    <TextField
                      label="Enter Text for TTS Prompt"
                      variant="outlined"
                      value={ttsLongPromptText}
                      onChange={(e) => setTTSLongPromptText(e.target.value)}
                      fullWidth
                      style={{ marginBottom: "10px", width: "40%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      marginBottom: "15px",
                      justifyContent: "space-around",
                    }}
                  >
                    <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                      <Select
                        labelId="node-type-label"
                        id="node-type"
                        value={ttsLongPromptLanguage}
                        onChange={(e) =>
                          setTtsLongPromptLanguage(e.target.value)
                        }
                      >
                        <MenuItem value="1" disabled>
                          Select Language
                        </MenuItem>
                        <MenuItem value="en-US">English</MenuItem>
                        <MenuItem value="hi-IN">Hindi</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                      <Select
                        labelId="node-type-label"
                        id="node-type"
                        value={ttsLongPromptVoiceType}
                        onChange={(e) =>
                          setTtsLongPromptVoiceType(e.target.value)
                        }
                      >
                        <MenuItem value="1" disabled>
                          Select Voice Type
                        </MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <InputLabel id="background-variant-label">
                    Select Greet Short
                  </InputLabel>

                  <Select
                    labelId="node-type-label"
                    label="Select Greet Short"
                    id="node-type"
                    value={selectIvrGreetShort}
                    onChange={(e) => setSelectIvrGreetShort(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Greet Short
                    </MenuItem>
                    <MenuItem value="say">Say</MenuItem>
                    <MenuItem value="tts_prompt">TTS Prompt</MenuItem>
                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                </FormControl>

                {selectIvrGreetShort == "say" ? (
                  <TextField
                    label="Enter Greet Short Message"
                    variant="outlined"
                    value={greetShortMessage}
                    onChange={(e) => setGreetShortMessage(e.target.value)}
                    fullWidth
                    style={{ marginBottom: "10px", width: "40%" }}
                  />
                ) : (
                  <div style={{ marginBottom: "10px", width: "40%" }}></div>
                )}
              </div>

              {/* <------------------------tts fields for greet short-----------------> */}
              {selectIvrGreetShort == "tts_prompt" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      marginBottom: "15px",
                      justifyContent: "space-around",
                    }}
                  >
                    <TextField
                      label="Enter Name for TTS Prompt"
                      variant="outlined"
                      value={ttsShortPromptName}
                      onChange={(e) => setTTSShortPromptName(e.target.value)}
                      fullWidth
                      style={{ marginBottom: "10px", width: "40%" }}
                    />
                    <TextField
                      label="Enter Text for TTS Prompt"
                      variant="outlined"
                      value={ttsShortPromptText}
                      onChange={(e) => setTTSShortPromptText(e.target.value)}
                      fullWidth
                      style={{ marginBottom: "10px", width: "40%" }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      marginBottom: "15px",
                      justifyContent: "space-around",
                    }}
                  >
                    <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                      <Select
                        labelId="node-type-label"
                        id="node-type"
                        value={ttsShortPromptLanguage}
                        onChange={(e) =>
                          setTtsShortPromptLanguage(e.target.value)
                        }
                      >
                        <MenuItem value="1" disabled>
                          Select Language
                        </MenuItem>
                        <MenuItem value="en-US">English</MenuItem>
                        <MenuItem value="hi-IN">Hindi</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                      <Select
                        labelId="node-type-label"
                        id="node-type"
                        value={ttsShortPromptVoiceType}
                        onChange={(e) =>
                          setTtsShortPromptVoiceType(e.target.value)
                        }
                      >
                        <MenuItem value="1" disabled>
                          Select Voice Type
                        </MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  label="Enter Max Failures"
                  variant="outlined"
                  value={IvrMaxFailures}
                  onChange={(e) => setIvrMaxFailures(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
                <TextField
                  label="Enter Max Timeout"
                  variant="outlined"
                  value={IvrMaxTimeout}
                  onChange={(e) => setIvrMaxTimeout(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  label="Timeout(ms)"
                  variant="outlined"
                  value={ivrTimeout}
                  onChange={(e) => setIvrTimeout(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={ivrSelectIvrExitAction}
                    onChange={(e) => setIvrSelectIvrExitAction(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Exit Action
                    </MenuItem>
                    <MenuItem value="IVR">IVR</MenuItem>
                    <MenuItem value="QUEUE">QUEUE</MenuItem>
                    <MenuItem value="PLAYBACK">PLAYBACK</MenuItem>
                    <MenuItem value="VOICEMAIL">VOICEMAIL</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={ivrSelectIvrExitName}
                    onChange={(e) => setIvrSelectIvrExitName(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Exit Name
                    </MenuItem>
                    {exitNameList && ivrSelectIvrExitAction != 1 ? (
                      exitNameList.map((option) => (
                        <MenuItem
                          key={
                            option.ivr_menu_uuid
                              ? option.ivr_menu_uuid
                              : option.call_center_queue_uuid
                              ? option.call_center_queue_uuid
                              : option.pmt_uuid
                              ? option.pmt_uuid
                              : option.extension_uuid
                          }
                          value={
                            option.ivr_menu_uuid
                              ? option.ivr_menu_uuid
                              : option.call_center_queue_uuid
                              ? option.call_center_queue_uuid
                              : option.pmt_uuid
                              ? option.pmt_uuid
                              : option.extension_uuid
                          }
                        >
                          {option.ivr_menu_name
                            ? option.ivr_menu_name
                            : option.queue_name
                            ? option.queue_name
                            : option.prompt_name
                            ? option.prompt_name
                            : option.extension}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <div
                  style={{
                    marginBottom: "10px",
                    width: "40%",
                    display: "flex",
                  }}
                >
                  <p style={{ marginRight: "50px" }}>Status</p>
                  <FormControlLabel
                    style={{ marginBottom: "10px", width: "40%" }}
                    // label={"Status"}

                    control={
                      <IOSSwitch
                        checked={ivrStatus}
                        onChange={(e) => setIvrStatus(e.target.checked)}
                        color="primary"
                      />
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  label="Enter Description"
                  variant="outlined"
                  value={ivrDescription}
                  onChange={(e) => setIvrDescription(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
                <div style={{ marginBottom: "10px", width: "40%" }}></div>
              </div>

              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#006d77",
                  marginBottom: "20px",
                  textAlign: "start",
                  marginLeft: "50px",
                }}
              >
                Call Settings
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={ivrSelectDirectDial}
                    onChange={(e) => setIvrSelectDirectDial(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Direct Dial
                    </MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={ivrSelectRingBack}
                    onChange={(e) => setIvrSelectRingBack(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Ring Back
                    </MenuItem>
                    <MenuItem value="${us-ring}">us-ring</MenuItem>
                    <MenuItem value="${fi-ring}">fi-ring</MenuItem>
                    <MenuItem value="${fr-ring}">fr-ring</MenuItem>
                    <MenuItem value="${pt-ring}">pt-ring</MenuItem>
                    <MenuItem value="${hk-ring}">hk-ring</MenuItem>
                    <MenuItem value="${hu-ring}">hu-ring</MenuItem>
                    <MenuItem value="${il-ring}">il-ring</MenuItem>
                    <MenuItem value="${in-ring}">in-ring</MenuItem>
                    <MenuItem value="${jp-ring}">jp-ring</MenuItem>
                    <MenuItem value="${ko-ring}">ko-ring</MenuItem>
                    <MenuItem value="${pk-ring}">pk-ring</MenuItem>
                    <MenuItem value="${pl-ring}">pl-ring</MenuItem>
                    <MenuItem value="${ro-ring}">ro-ring</MenuItem>
                    <MenuItem value="${rs-ring}">rs-ring</MenuItem>
                    <MenuItem value="${it-ring}">it-ring</MenuItem>
                    <MenuItem value="${ru-ring}">ru-ring</MenuItem>
                    <MenuItem value="${sa-ring}">sa-ring</MenuItem>
                    <MenuItem value="${tr-ring}">tr-ring</MenuItem>
                    <MenuItem value="${uk-ring}">uk-ring</MenuItem>
                    <MenuItem value="${bong-ri}ng">bong-ring</MenuItem>
                    <MenuItem value="${au-ring}">au-ring</MenuItem>
                    <MenuItem value="${be-ring}">be-ring</MenuItem>
                    <MenuItem value="${ca-ring}">ca-ring</MenuItem>
                    <MenuItem value="${cn-ring}">cn-ring</MenuItem>
                    <MenuItem value="${cy-ring}">cy-ring</MenuItem>
                    <MenuItem value="${cz-ring}">cz-ring</MenuItem>
                    <MenuItem value="${de-ring}">de-ring</MenuItem>
                    <MenuItem value="${dk-ring}">dk-ring</MenuItem>
                    <MenuItem value="${dz-ring}">dz-ring</MenuItem>
                    <MenuItem value="${eg-ring}">eg-ring</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  label="Enter Caller ID Name Prefix"
                  variant="outlined"
                  value={ivrCallerIdPrefix}
                  onChange={(e) => setIvrCallerIdPrefix(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={ivrInvalidSound}
                    onChange={(e) => setIvrInvalidSound(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Invalid Sound
                    </MenuItem>
                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={ivrExitSound}
                    onChange={(e) => setIvrExitSound(e.target.value)}
                  >
                    <MenuItem value="1" disabled>
                      Select Exit Sound
                    </MenuItem>
                    {promptList ? (
                      promptList.map((option) => (
                        <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                          {option.prompt_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No data</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <div style={{ marginBottom: "10px", width: "40%" }}></div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px",
              }}
            >
              <Button
                style={{
                  color: "error",
                  transform: "scale(.9)",
                  marginTop: "10px",
                }}
                variant="outlined"
                color="error"
                onClick={() => {
                  setAddIvrModal(false);
                  setIvrName("");
                  setIvrExtention("");
                  setSelectIvrGreetLong("1");
                  setSelectIvrGreetShort("1");
                  // setIvrMaxFailures("");
                  // setIvrMaxTimeout("");
                  // setIvrTimeout("");
                  // setIvrSelectIvrExitAction("1");
                  setIvrSelectDirectDial("1");
                  // setIvrSelectRingBack("1");
                  setIvrCallerIdPrefix();
                  // setIvrInvalidSound("1");
                  // setIvrExitSound("1");
                  setIvrStatus(true);
                  setSelectedConfigurationType("1");
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  transform: "scale(.9)",
                  marginTop: "10px",
                }}
                variant="contained"
                color="success"
                onClick={handleAddIvr}
              >
                Add IVR
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      {/* Modal for Voice Bot */}
      <Modal
        open={AddVoiceBotModal}
        onClose={() => {
          setAddIvrModal(false);
          setSelectedConfigurationType("1");
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "50%",
            maxWidth: 1300,
            padding: "20px",
            borderRadius: "20px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            backgroundColor: "#f9f9f9",
            margin: "auto",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              maxHeight: "100%",
              overflowY: "auto",
              paddingRight: "7px",
              borderRadius: "inherit",
            }}
          >
            <p
              style={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#006d77",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Add Voice Bot Configuration
            </p>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <TextField
                  label="Enter Configuration Name"
                  variant="outlined"
                  value={voiceBotConfigurationName}
                  onChange={(e) => setVoiceBotConfigurationName(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
                <TextField
                  label="Enter Description"
                  variant="outlined"
                  value={voiceBotDescription}
                  onChange={(e) => setVoiceBotDescription(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "10px", width: "40%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: "15px",
                  justifyContent: "space-around",
                }}
              >
                <FormControl style={{ marginBottom: "10px", width: "40%" }}>
                  <Select
                    labelId="node-type-label"
                    id="node-type"
                    value={voiceBotMaxRetry}
                    onChange={(e) => setVoiceBotMaxRetry(e.target.value)}
                  >
                    <MenuItem value="0" disabled>
                      Select Max Retry
                    </MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </Select>
                </FormControl>
                <div style={{ marginBottom: "10px", width: "40%" }}></div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "80px",
              }}
            >
              <Button
                style={{
                  color: "error",
                  transform: "scale(.9)",
                  marginTop: "10px",
                }}
                variant="outlined"
                color="error"
                onClick={() => {
                  setAddVoiceBotModal(false);
                  setVoiceBotConfigurationName("");
                  setVoiceBotDescription("");
                  setVoiceBotMaxRetry("0");
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  transform: "scale(.9)",
                  marginTop: "10px",
                  width: "100px",
                }}
                variant="contained"
                color="success"
                onClick={handleAddVoiceBotConfiguration}
              >
                Add
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      {/* Save & restore layout buttons  */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
        }}
      >
        <FormControl style={{ margin: "10px", minWidth: 200 }}>
          <InputLabel id="background-variant-label">Background</InputLabel>
          <Select
            labelId="background-variant-label"
            value={backgroundVariant}
            onChange={handleVariantChange}
            label="Background"
            sx={{
              height: "35px",
              fontSize: "14px",
            }}
          >
            <MenuItem value="dots">Dots</MenuItem>
            <MenuItem value="cross">Cross</MenuItem>
            <MenuItem value="lines">Lines</MenuItem>
          </Select>
        </FormControl>

        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSaveLayout}
            style={{ margin: "10px", minWidth: 180 }}
          >
            Save layout
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleRestoreLayout}
            style={{ margin: "10px", minWidth: 180 }}
          >
            Restore layout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkflowBuilder;
